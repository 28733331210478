import React, { useEffect, useState } from "react";
import PageContent from "../../components/Ui/PageContent";
import Layout from "./../../bootstrap/Layout/index";

function RefundPolicy() {
  //const [privacyDetails, setPrivacyDetails] = useState(null);
  const [refundDetails, setRefundDetails] = useState(null);
  const [userProfileInfo, setUserProfileInfo] = useState(null);

  useEffect(() => {
    setUserProfileInfo(JSON.parse(localStorage.getItem("userProfileInfo")));
  }, []);

  const getRefundContent = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/pages/refundpage`
    );
    const result = await response.json();
    setRefundDetails(result?.data);
  };

  useEffect(() => {
    try {
      getRefundContent();
    } catch (error) {
      console.log("Refund policy page error", error);
    }
  }, []);

  return (
    <Layout title="Refund Policies" userProfileInfo={userProfileInfo}>
      <PageContent content={refundDetails} />
    </Layout>
  );
}

export default RefundPolicy;
