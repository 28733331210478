import React from "react";
import { NotFoundPageTitle } from "./NotFound.styles";

const NotFound = () => {
  return (
    <div>
      <NotFoundPageTitle>NotFound</NotFoundPageTitle>
    </div>
  );
};

export default NotFound;
